import Vue from "vue";

/*TITLE*/
document.title = "Residencial Costa | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Costa";
Vue.prototype.$subtitle = "Espacios serenos, llenos de vida y movimiento.  Con libertad, comodidad, relajación interior y privacidad.  Ntra. Sra. de Jesús (Santa Eulària del Río) Ibiza";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Costa";
Vue.prototype.$introSubtitle = "Espacios serenos, llenos de vida y movimiento.  Con libertad, comodidad, relajación interior y privacidad.  Ntra. Sra. de Jesús (Santa Eulària del Río) Ibiza";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-bottega-caliza--20230127090104.jpg";
Vue.prototype.$image_kitchen = "porcelanosa_residencialcosta_exterior-v04_hd--20230127090112.jpg";
Vue.prototype.$image_bath1 = "banop-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-spiga-bottega-acero--20230127090126.jpg";
Vue.prototype.$image_bath2 = "banosec-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-spiga-bottega-acero--20230127090103.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-bottega-caliza--20230127090104.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "porcelanosa_residencialcosta_exterior-v04_hd--20230127090112.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-spiga-bottega-acero--20230127090126.jpg",
  },
  {
    src: "banop-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-spiga-bottega-caliza--20230127090133.jpg",
  },
  {
    src: "banop-pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-acero--20230127090142.jpg",
  },
  {
    src: "banop-pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-caliza--20230127090153.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-spiga-bottega-acero--20230127090103.jpg",
  },
  {
    src: "banosec-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-spiga-bottega-caliza--20230127090111.jpg",
  },
  {
    src: "banosec-pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-acero--20230127090121.jpg",
  },
  {
    src: "banosec-pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-caliza--20230127090129.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/RESIDENCIAL_COSTA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/BmkAThhXxD8";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carrer%20del%20paó%20número%206%20ntra%20sra%20de%20jesús%20santa%20eulària%20del%20río%20ibiza",
    text: "Carrer del Paó, número 6, Ntra. Sra. de Jesús (Santa Eulària del Río) Ibiza",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carrer%20del%20paó%20número%206%20ntra%20sra%20de%20jesús%20santa%20eulària%20del%20río%20ibiza",
    text: "Carrer del Paó, número 6, Ntra. Sra. de Jesús (Santa Eulària del Río) Ibiza",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:675973295",
    text: "675973295",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@residencialcostaibiza.com",
    text: "info@residencialcostaibiza.com",
  },
];
